<template>
  <div class="tabDetailWrap">
    <div class="tabDetail">
      <img class="tabLogo" :src="require('../../assets/image/notice/playNoticeLogo.png')" alt />
      <div class="logoRight">
        <span class="title">游玩须知</span>
        <div class="subtitle">
          <img :src="require('../../assets/image/notice/title.png')" alt />
        </div>
      </div>

      <div class="logoInfo">
        <div class="welcomeInfo">
          <img :src="require('../../assets/image/notice/welcome.png')" alt />
          <span>欢迎您来到铁定溜溜！我们一直致力于为游客提供安全舒适的游玩环境，为了您和他人的安全，我们特别提醒您入园前请仔细阅读《入园须知》。</span>
        </div>
      </div>
      <div class="detailWrap">
        <ul>
          <li v-for="(item,index) in dataInfo" :key="index">
            <span v-if="index%7==1" class="num g1">{{index+1}}</span>
            <span v-else-if="index%7==2" class="num g2">{{index+1}}</span>
            <span v-else-if="index%7==3" class="num g3">{{index+1}}</span>
            <span v-else-if="index%7==4" class="num g4">{{index+1}}</span>
            <span v-else-if="index%7==5" class="num g5">{{index+1}}</span>
            <span v-else-if="index%7==6" class="num g6">{{index+1}}</span>
            <span v-else-if="index%7==0" class="num">{{index+1}}</span>
            <span class="detail">{{item.notice}}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import axios from "axios";
import config from "@/utils/config";
export default {
  data() {
    return {
      dataInfo: new Array()
    };
  },
  mounted() {
    this.getData(config.serviceNotice);
  },
  computed: {
    baseUrl() {
      return config.webBaseUrl;
    }
  },
  methods: {
    async getData(url) {
      try {
        this.id = this.$route.query.id;
        let res = await axios.get(url, {
          params: {
            id: this.id
          }
        });
        if (res.data.code == "0" && res.data.data instanceof Array) {
          if (res.data.data.length > 0) {
            let tempData = JSON.parse(JSON.stringify(res.data.data));
            if (tempData.length > 0) {
              this.dataInfo = tempData;
            }

            // console.log(
            //   "res.data.data:",
            //   this.dataInfo,
            //   this.dataInfo instanceof Array
            // );
          }
        }
      } catch (e) {
        console.log("res err:", e);
      }
    }
  }
};
</script>

<style scoped lang="scss" rel="stylesheet/scss">
@import "src/assets/styles/variables.scss";

.tabDetailWrap {
  min-height: 500px;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 50px;
}

// 常见问题
.tabDetailWrap.question {
  .logoRight {
    .title {
      color: $main-red;
    }
  }
  ul {
    margin-top: 30px;
    .groupQ {
      margin-top: 60px;
      margin-bottom: 54px;
    }
    .info {
      display: inline-block;
      position: relative;
      width: calc(100% - 130px);
      padding: 35px 0px 35px 47px;

      border-radius: 20px;
      color: $main-white;

      &.qInfo {
        background: $main-redQuestion;
        margin-left: 25px;
        font-size: 33px;
        img {
          position: absolute;
          left: -20px;
        }
      }
      &.aInfo {
        background: $main-yellow;
        margin-right: 25px;
        font-size: 22px;
        img {
          position: absolute;
          right: -20px;
        }
      }
    }
  }
}
.tabDetail {
  // padding-left: 40px;
  // padding-right: 40px;
  // padding-top: 50px;
  .tabLogo {
    vertical-align: super;
    margin-right: 47px;
    width: 104px;
    height: 100px;
  }
  .logoRight {
    display: inline-block;
    .title {
      font-size: 45px;
      font-weight: bold;
      color: $main-blueDark;
      margin-bottom: 20px;
    }
    .subtitle {
      margin-top: 20px;
    }
  }
  .logoInfo {
    margin-top: 65px;
    padding: 30px 50px 30px 15px;
    background: $main-blueMenu;
    border-radius: 10px;
    .welcomeInfo {
      img {
        vertical-align: top;
      }
      span {
        // float: left;
        display: inline-block;
        width: calc(100% - 100px);
        margin-right: 2px;
        margin-left: 20px;
        color: $main-white;
        font-size: 15px;
      }
    }
  }
  .detailWrap {
    margin-top: 30px;
    // ul {
    li {
      padding: 20px 0px;
      display: table;
      .num {
        display: inline-block;
        width: 34px;
        height: 34px;
        line-height: 34px;
        // margin-top: 10px;
        border-radius: 50%;
        background: $main-red;
        vertical-align: top;
        text-align: center;
        color: $main-white;
        &.g1 {
          background: $main-blueDark;
        }
        &.g2 {
          background: $main-red;
        }
        &.g3 {
          background: $bg-orange2;
        }
        &.g4 {
          background: $main-green;
        }
        &.g5 {
          background: $main-greenGrass;
        }
        &.g6 {
          background: $main-orange;
        }
      }
      .detail {
        display: table-cell;
        vertical-align: middle;
        padding-left: 10px;
        // display: inline-block;
        width: calc(100% - 100px);
        margin-left: 27px;
        font-size: 13px;
        color: $font-grayDarkD;
        line-height: 1.7;
      }
    }
    // }
  }
}
</style>
